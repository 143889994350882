<template>
  <div class="content">
    <HeaderIndex @salvedPage="dataPages" />

    <div class="content-cards">
      <b-card
        v-for="page of fanpages"
        :key="page.id"
        :title="'#0' + page.id + ' ' + page.fb_fanpage_title"
        :img-src="page.fb_link_img"
        img-alt="Image"
        img-top
        tag="article"
        style="width: 373px;"
        class="mb-2 mr-4 card-fanpage"
        @click="$router.push('lead-ads/fanpage/'+page.id)"
      >
        <div class="pl-4 ml-3">
          <div class="content-info">
            <div id="content-status">
              <div class="status" :class="`status bg-${page.status.type}`"></div>
            </div>
            <span class="text-data-info" :class="`status-text-${page.status.type}`">{{ page.status.message }}</span>
          </div>
          <div class="leads-cap content-info">
            <img :src="imgPeople" alt="Pessoa" />
            <span class="text-data-info"
              >{{ getNameLeads(page.total_leads) }}</span
            >
          </div>
          <div class="last-capture-made content-info">
            <img :src="imgRelogio" alt="Relógio" width="16" height="18" />
            <span class="text-data-info">{{
              getTextCapture(page.last_catch)
            }}</span>
          </div>
          <div class="integrations content-info">
            <img :src="imgIntegration" alt="Integração" />
            <span class="text-data-info"
              >{{ getNameIntegrations(page.integrations) }}</span
            >
          </div>
        </div>
      </b-card>
      <VFacebookLoginScope :app-id="getIdApp" />
    </div>

    <Footer
      v-model="currentPage"
      :rows="getTotalRows"
      :perPage="getPerPage"
      @change="dataPages"
    />
    
  </div>
</template>
<script>
import Footer from "./Footer.vue";
import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();
import HeaderIndex from './HeaderIndex.vue'
import {mapMutations} from 'vuex'

import { VFBLoginScope as VFacebookLoginScope } from "vue-facebook-login-component";

export default {
  name: "Listagem",
  components: {
    HeaderIndex,
    Footer,
    VFacebookLoginScope,
  },
  data: () => ({
    fanpages: [],
    valuesForPaginate: null,
    imgPeople: require("@/assets/icons/people.svg"),
    imgIntegration: require("@/assets/icons/lightning.svg"),
    imgRelogio: require("@/assets/icons/relogio.svg"),
    rows: 0,
    currentPage: 1,
    perPage: 0,
    numberPage: 0,
  }),
  computed: {
    getIdApp: () => process.env.FACEBOOK_APP_ID,
    getTotalRows: function () {
      return this.rows;
    },
    getPerPage: function () {
      return this.perPage;
    },
    getNameIntegrations: function(){
      return (total)=>{
        return total > 1 ? total+' Integrações' : total+' Integração'
      }
    },
    getNameLeads: function(){
      return (total)=>{
        return total > 1 ? total+' Leads capturado' : total+' Lead capturado'
      }
    },
  },
  created: async function () {
    let responseApi = await this.dataPages();
    if(responseApi.error){
      this.setMessage({message: 'Falha ao carregar dados de fanpages', bg:'danger'})
    }
    
    this.rows = responseApi.total;
    this.currentPage = responseApi.current_page;
    this.perPage = responseApi.per_page;
  },
  methods: {
    ...mapMutations(['loading', 'setMessage']),
    
    getTextCapture: (lastCatch) =>
      !lastCatch ? "Não há capturas" : "Ultíma captura feita há " + lastCatch,
    
    async dataPages(page) {
      this.loading(true)
      if(!page && !this.numberPage){
        this.numberPage = 1
      }else if(page !== undefined){
        this.numberPage = page;
      }
      let responseApi = await LeadAdApiService.read("?page="+this.numberPage);
      this.fanpages = responseApi.data;
      this.rows = responseApi.total;
      this.loading(false)
      return responseApi;
    },
  },
};
</script>

<style >

.card-img-top {
  width: 373px;
  height: 217.91px;
}

.content {
  padding-left: 132px;
  padding-top: 35px;
}

.content-cards {
  margin-top: 34.51px;
  justify-content: center;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.card {
  border-radius: 5px 5px 0px 0px !important;
  margin-right: 30px;
  margin-bottom: 28px !important;

}
.card-body,
.card-fanpage {
  border: 0.1px solid #ededf0 !important;
  box-sizing: border-box !important;
  border-radius: 0 0 10px 10px !important;
  padding-left: 0;
  cursor: pointer;
}

.card-title {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-left: 38px;
}

.card-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  color: #333333;
  margin-left: 38px;
}

.content-info {
  margin-top: 21.19px;
}
#content-status {
  width: 16px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  width: 10px;
  height: 10.02px;
  border-radius: 50px;
}

.content-info {
  margin-top: 21.19px;
  display: flex;
  align-items: center;
}

.text-data-info {
  margin-left: 26px;
}

.status-text-error, .status-text-warning, .status-text-success{
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

.status-text-error{
  color: red;

}
.status-text-warning{
  color: #FFCB37;
}
.status-text-successes{
  color: #00E4A0;
}

.bg-successes{
  background-color: #00E4A0;
}
 .bg-error{
   background-color: red;
 }

</style>