<template>
  <div>
    <Header>

        <div>
          <router-link  to='/lead-ads/leadgen/' class="btn btn-historic">
            <span>Visualizar Histórico</span>
          </router-link>
          <button v-b-modal.modal-add-fanPage class="btn-open-modal-fanpage">
            Associar Fanpage
          </button>
        </div>

        <template v-slot:link>
          <span class="text-item">
              Listagem de Fanpages
          </span>
        </template>
    </Header>
    <ModalAddFanPage
          idModal="modal-add-fanPage"
          @salvedPage="salvedPage"
        />  
  </div>
</template>

<script>
import ModalAddFanPage from "./ModalAddFanPage.vue";
import Header from "./Header.vue";

export default {
  name:'HeaderIndex',
  components: {
    ModalAddFanPage,
    Header
  },
  methods: {
    salvedPage(){
      this.$emit('salvedPage');
    }
  },
}
</script>
<style scoped>
  
.btn-historic,
.btn-open-modal-fanpage {
  height: 50px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  border: 0px;
}

.btn-historic>span{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.btn-open-modal-fanpage:active {
  border: 0px;
}
.btn-historic {
  width: 216px;
  background-color: var(--background-color-white-light);
  margin-right: 25px;
}

.btn-open-modal-fanpage {
  width: 209px;
  background-color: var(--background-color-green);
  color: #ffffff;
  border: 0px;
}

.btn-open-modal-fanpage:hover {
  color: #ffffff;
  border: 0px;
}
</style>