<template>
  <div>
    <Modal :idModal="idModal">
      <div class="container">
        <div class="row p-4">
          <div class="col-12">
            <img
              class="float-right"
              :src="require('@/assets/icons/close.svg')"
              alt="Voltar"
              @click="$bvModal.hide('modal-add-fanPage')"
              id="icon-close"
            />
          </div>
          <div class="col-12" id="content-title">
            <p class="m-0" id="title-modal">Associar Fanpage</p>
            <small>Associe sua fanpage em poucos passos</small>
          </div>
          <div class="col-12">
            <p class="title-step">01 Faça o login</p>
          </div>
          <div class="col-12">
            <LoginFacebook @loged="loged" :isLoged="isLoged" />
          </div>

          <template v-if="isLoged">
            <div class="col-12">
              <p class="title-step mt-2">02 Selecione a Fanpage</p>
            </div>
          <div class="col-12 list-fanpage-select">
            <multiselect
              v-model="page"
              :options="options"
              :show-labels="false"
              :searchable="false"
              selectedLabel="Selecionado"
              label="name"
              track-by="name"
              @input="selectFanpage"
              :disabled="this.options.length === 0"
            >
              <template slot="option" slot-scope="props"

                ><img
                  class="option__image"
                  :src="props.option.picture.data.url"
                  :alt="props.option.name"
                />
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template> 
            </multiselect>
          </div>
            <div class="col-12">
              <button
                class="btn"
                id="btn-add-fanpage"
                @click="create"
                :disabled="disabledBtn"
              >
                Adicionar Fanpage
              </button>
            </div>
          </template>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from "../BaseModal.vue";
import LoginFacebook from "./LoginFacebook.vue";
import { mapMutations } from "vuex";

import LeadAdService from "@/services/resources/LeadAdService.js";
const LeadAdApiService = LeadAdService.build();
import Multiselect from "vue-multiselect";

import UserLoggedService from "@/services/resources/UserLoggedService";
const serviceMe = UserLoggedService.build();

export default {
  name: "ModalAddFanPage",
  props: {
    idModal: String,
  },
  components: {
    Modal,
    LoginFacebook,
    Multiselect,
  },
  data: () => ({
    isLoged: false,
    pages: [],
    page: {'name':'Nome da fanpage'},
    disabledBtn: true,
    IdsFanpage: [],
    dataUser: [],
    value: 'Nome da fanpage',
    options: [
    ],
  }),
  created: async function () {
    let idsPage = await LeadAdApiService.read("/fanpage-ids");
    this.IdsFanpage.push(
      ...idsPage.map((item) => {
        return item.fb_fanpage_id;
      })
    );
  },
  watch: {
    IdsFanpage: function () {
      if (this.dataUser.data) {
        this.options = [];
        this.options = this.getPages(this.dataUser.data, true);
        this.isLoged = false;
      }
    },
  },
  methods: {
    ...mapMutations(["setMessage", "alertError"]),
    loged(dataUser) {
      if (dataUser) {
        this.dataUser = dataUser;
        this.options = this.getPages(dataUser.data);
        this.isLoged = true;
      }
    },
    getPages(dataUser, inWatch = false) {
      let pages = dataUser
        .map((item) => {
          return !this.IdsFanpage.includes(+item.id) ? item : undefined;
        })
        .filter((item) => item);
      if (!pages[0] && !inWatch) {
        this.setMessage({
          message: "Não foi encontrado Fanpage",
          bg: "danger",
        });
      }
      return pages;
    },
    async selectFanpage(page) {
      let check = await LeadAdApiService.read('/check-used-fanpage/'+page.id);
      if(check.used){
        this.page = {'name':'Nome da fanpage'}
        this.setMessage({
          message: "Desculpe mas já existe integração feita com essa Fanpage",
          bg: "danger",
        });
        return false;
      }

      if(!page){
        this.page = { name: "Nome da fanpage" };
      }else{
        this.page = page;
      }
      this.disabledBtn = page ? false : true;
    },
    async create() {
      if (this.page) {
        this.disabledBtn = true;
        let store = this.$store;
        const blogId = store.getters.blog.blog_id;
        let user = await serviceMe.search();
        const data = {
          blog_id: blogId,
          fb_fanpage_id: +this.page.id,
          fb_fanpage_title: this.page.name,
          fb_fanpage_token: this.page.access_token,
          created_by: user.user.ID,
          fb_link_img: "",
        };
        try {
          let fanpage = await LeadAdApiService.create(data);
          if (!fanpage.error) {
            this.IdsFanpage.push(fanpage.fb_fanpage_id);
            this.$emit("salvedPage");
            this.$bvModal.hide(this.idModal);
            this.page={'name':'Nome da fanpage'}
            return;
          }
          this.alertError(fanpage);
        } catch (e) {
          console.log(e);
          this.setMessage({
            message: "Desculpe falha ao salvar Fanpage",
            bg: "danger",
          });
        }
          this.page={'name':'Nome da fanpage'}
      }
    },
  },
};
</script>

<style >
button:focus {
  outline: unset;
  outline: unset;
}

.list-fanpage-select>.multiselect--active{
 width: 100%;
  border: 1px solid #ededf0;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.list-fanpage-select>.multiselect--active>.multiselect__content-wrapper>.multiselect__content>li>span{
  display: flex;
  align-items: center;
}

.list-fanpage-select>.multiselect--active>.multiselect__content-wrapper>.multiselect__content>li>span>.option__desc>.option__title {
  margin-left: 15px;
}
.multiselect__placeholder {
  color: #000;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select,
.multiselect__option--disabled {
  background: none;
}

.multiselect--disabled > .multiselect__tags,
.multiselect--disabled > .multiselect__tags > .multiselect__single {
  background-color: #d2d9e0 !important;
  border: 1px solid #ced4da;
}


#drop-pages__BV_toggle_ + ul > li {
  padding: 5px;
}

.btn-secondary + ul > li > a {
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}

.btn-secondary + .dropdown-menu {
  padding: 25.5px 25.1px;
  min-width: 100%;
}
.dropdown-item {
  padding: 0;
}
.dropdown-item > img {
  margin-right: 25.9px;
}

.dropdown-toggle::after {
  color: #dcdcdc;
}

.icon-fanpage {
  width: 45px;
  height: 45px;
}

#drop-pages > button {
  background-color: #ffffff !important;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  border: 1px solid #ededf0;
}

#btn-add-fanpage {
  margin-top: 30.68px;
  margin-bottom: 13.95px;
  width: 100%;
  height: 50px;
  background: #000916;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.modal-content.shadow {
  border-radius: 7px;
}

.modal-dialog.modal-md.modal-dialog-centered {
  width: 453px;
}

#icon-close {
  cursor: pointer;
}

#title-modal,
.title-step {
  color: #000000;
  align-items: center;
  font-weight: 600;
  font-style: normal;
}

#title-modal {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px !important;
}

.title-step {
  font-size: 14px;
  line-height: 17px;
}
/* 20.82 */
small {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #8c8a97;
}

#content-title {
  margin-bottom: 31.68px;
  margin-top: 15px;
}
</style>