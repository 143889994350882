<template>
  <Content>
      <Index />
  </Content>
</template>
<script>
import Content from "@/components/LeadAd/Content.vue";
import Index from "@/components/LeadAd/Listagem.vue";

export default {
  components: {
    Index,
    Content
  },
};
</script>